import { gql } from "@apollo/client";

export const UPDATE_WORKSPACE = gql`
  mutation UpdateWorkspace($input: UpdateWorkspaceInput!) {
    updateWorkspace(input: $input) {
      mrn
      name
      description
      selections {
        ...WorkspaceSelection
      }
    }
  }
`;
