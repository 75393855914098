import { gql } from "@apollo/client";

export const GET_WORKSPACE = gql`
  query GetWorkspace($mrn: String!) {
    workspace(mrn: $mrn) {
      ... on Workspace {
        mrn
        ownerMrn
        name
        description
        priorityFindings
      }
      ... on RequestError {
        code
        message
      }
      ... on NotFoundError {
        code
        message
      }
    }
  }
`;
