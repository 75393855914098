import { gql } from "@apollo/client";

export const GET_VULNERABILITY_SCORES = gql`
  query GetVulnerabilityScores(
    $entityMrn: String!
    $first: Int
    $after: String
    $orderBy: VulnerabilityScoreOrder
    $filter: VulnerabilityScoreFilter
  ) {
    vulnerabilityScores(
      entityMrn: $entityMrn
      first: $first
      after: $after
      orderBy: $orderBy
      filter: $filter
    ) {
      ... on VulnerabilityScoresConnection {
        totalCount
        edges {
          cursor
          node {
            mrn
            id
            asset {
              icon
              id
              mrn
              name
              tags {
                key
                value
              }
            }
            title
            state
            iconId
            epss {
              probability
              percentile
            }
            cvss {
              id
              value
              type
              vector
              source
            }
            riskScore
            scoreType
            riskFactors {
              mrn
              indicator
              title
              affected
              total
            }
            detectionSources {
              name
              url
              firstDetectedAt
              lastUpdatedAt
              fixedAt
              vendor
              version
            }
            firstDetectedAt
            publishedAt
            updatedAt
            tags {
              key
              value
            }
          }
        }
        filteredTotalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
      ... on RequestError {
        message
        code
      }
      ... on NotFoundError {
        message
        code
      }
    }
  }
`;
